.calendar-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 90%; /* Use uma porcentagem para responsividade ou defina uma largura fixa maior */
  max-width: 1200px; /* ou qualquer outra largura máxima desejada */
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100h; /* Novo: define uma altura baseada na altura da viewport */
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(100px, auto); /* Define uma altura mínima para as linhas da grade */
  gap: 5px;
  flex: 1;
  margin-left: 30px;
}

.grid div {
  border-radius: 4px;
  transition: background-color 0.3s;
}


/* Destaca o dia atual */
.grid div.today {
  background-color: #e3f2fd;
}

/* Estiliza os dias de outro mês */
.grid div.dimmed {
  color: #aaa;
}

/* Estiliza o cabeçalho dos dias da semana */
.day-header {
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  background: #f7f7f7;
}

.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 120px;
}

.week div {
  text-align: center;
  cursor: pointer;
  /* Garante que cada célula da grade preencha a linha */
}
.week div:hover {
  background-color: #f0f0f0;
}

/* Botões de navegação */
.button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #e3e3e3;
}


.button {
  background-color: #f7f7f7; /* Cor de fundo cinza claro */
  color: #333; /* Cor do texto cinza escuro */
  border: 1px solid #ddd; /* Borda cinza claro */
  padding: 10px 20px; /* Espaçamento interno */
  font-size: 16px; /* Tamanho da fonte */
  border-radius: 4px; /* Bordas arredondadas */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  outline: none; /* Remove o contorno padrão quando focado */
  transition: background-color 0.3s, box-shadow 0.3s; /* Transição suave para hover e foco */
}

.button:hover, .button:focus {
  background-color: #e3e3e3; /* Cor de fundo mais escura quando o botão é passado ou focado */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para dar um efeito elevado */
}

/* Ajusta o espaçamento entre os botões, se necessário */
.button:not(:last-child) {
  margin-right: 10px;
}

.event {
  display: flex;
  justify-content: center;
  align-items: center;
  color: blue;
  font-size: 9px;
  width: 100%;
}

.day-cell {

}