.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .button {
    background-color: #f7f7f7; /* Cor de fundo cinza claro */
    color: #333; /* Cor do texto cinza escuro */
    border: 1px solid #ddd; /* Borda cinza claro */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 16px; /* Tamanho da fonte */
    border-radius: 4px; /* Bordas arredondadas */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    outline: none; /* Remove o contorno padrão quando focado */
    transition: background-color 0.3s, box-shadow 0.3s; /* Transição suave para hover e foco */
  }
  
  .button:hover, .button:focus {
    background-color: #e3e3e3; /* Cor de fundo mais escura quando o botão é passado ou focado */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para dar um efeito elevado */
  }